exports.components = {
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-pages-404-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/pages/404.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-pages-404-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-businesshour-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/Businesshour.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-businesshour-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-contactpage-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/Contactpage.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-contactpage-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-event-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/Event.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-event-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-eventpage-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/Eventpage.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-eventpage-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-frontpage-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/Frontpage.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-frontpage-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-gallery-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/Gallery.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-gallery-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-gallerypage-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/Gallerypage.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-gallerypage-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-news-article-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/NewsArticle.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-news-article-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-newspage-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/Newspage.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-newspage-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-offer-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/Offer.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-offer-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-offerpage-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/Offerpage.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-offerpage-js" */),
  "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-page-js": () => import("./../../../node_modules/@weahead/gatsby-theme-chocolate-chip/src/templates/Page.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-chocolate-chip-src-templates-page-js" */)
}

